.auth_info {
    padding: 15px 20px;
    border: 1px solid #3fa7d6;
    height: calc(100vh - 210px);
    width: 100%;
    /* min-height: 100%; */
    border-radius: 10px;
    margin-left: none;
    background: #F9F9F9;
    overflow: auto;
}